<template>
  <div>
    <div class="row bg-title">
      <h3 class="text-uppercase">{{ title }}</h3>
    </div>
    <div class="mt-10">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>
<style scoped>
.bg-title {
  background: #1d2b58 !important;
  color: white;
  padding: 10px 10px;
}
</style>
